// messages.js

export const messages = {
  
    en: {
      background: 
      {
        title_s_1: "Why we decided to develop this service",
        title_1_1: "Have you faced challenges accessing government data? Was it difficult to analyze how unemployment rates or exchange rate fluctuations affect your investment?",
        title_2_1: "",
        desc_1: "Welcome to Nextdex! We created this service to assist novice investors. We provide indices optimized for user needs by compiling national data that most beginners find difficult to access. Additionally, we respect ESG values such as corporate social responsibility and climate change, and include ESG metrics in our indices to accommodate investors' high interest in ESG.",
        title_s_2: "How to use our service",
        title_1_2: "How to utilize your index",
        desc_2: "Our data at Nextdex can assist you in making investment decisions! We have analyzed detailed economic data spanning the past 10 years and ESG compliance rates of listed companies on KOSPI to optimize our service for your investment style.",
        title_s_3: "References",
        title_1_3: "Reliable data sources only",
        desc_3: "Nextdex only uses reliable national data sources. We utilized open APIs from the Bank of Korea and other public portals to gather all necessary data for index calculation, including unemployment rates, economic growth rates, and ESG compliance rates.",
      },
    },
    ko: {
      background: 
      {
        title_s_1: "서비스 배경",
        title_1_1: "국가 데이터에 접근하기 어려웠나요?",
        title_2_1: "실업률이나 환율 변동이 무엇을 나타내는지, 그 중요성은 어떠한지 분석하기 어려웠나요?",
        desc_1: "Nextdex에 오신 것을 환영합니다! 우리는 초보투자자들의 투자를 돕고자 이 서비스를 만들게 되었습니다. 초보자들이 접하기 힘든 국가데이터를 니즈에 맞게 최적화하여 산출한 지수를 보여드립니다. 또한, 우리는 지구온난화 및 기업의 사회적 공헌 등에 대한 ESG 가치를 존중하며 투자자들의 ESG에 대한 높은 관심을 환영합니다. 이에 따라 ESG에 대한 척도 역시 포함하여 지수에 산출합니다.",
        title_s_2: "서비스 활용법",
        title_1_2: "지표를 이렇게 활용하세요!",
        desc_2: "Nextdex에서 제공하는 데이터로 당신의 투자결정을 보조할 수 있습니다! 최근 10년간 범국가적 경제 데이터, 코스피 상장사들의 ESG 준수율 등을 세밀하게 분석하여 당신의 투자성향에 최적화하였습니다.",
        title_s_3: "참조 자료",
        title_1_3: "공신력 있는 데이터를 사용했습니다",
        desc_3: "Nextdex는 공신력 있는 국가데이터 자료만 사용합니다. 지수 산출에 필요한 실업률, 경제성장률, ESG 준수율 등 모든 데이터는 한국은행 및 기타 공공포털의 open API 소스를 활용하였습니다.",
      },
    }
  };
